import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Tooltip,
} from '@material-ui/core';
import { NotificationsNoneOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { encrypt } from '../utils/crypt';
import { env } from '../config/environment';
import { setBadges, setCertificates, setCarnets, setView, setActive } from '../actions';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from '../api';
import moment from 'moment';
import Swal from 'sweetalert2';
import colors from '../assets/styles/colors';
import Backdrop from './Backdrop';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const Notification = (props) => {
  const { userId, setBadges, setCertificates, setCarnets, setView, setActive } = props;
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [credential, setCredential] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const idOrganizationBackoffice = window.location.origin === env.mincienciasURL ? env.idMinciencias : 1;

  /* translation */
  const [t] = useTranslation(['alerts', 'buttons']);

  useEffect(() => {
    getNotifications().catch(() => {
      //history.push('/500');
      //window.location.reload();
    });

    // eslint-disable-next-line
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = async () => {
    const { data } = await axios.post(`/alertNotifications/getAlerts`, { id_usuarios: userId });
    const alertFiltered = data.alerts.filter(
      (item) => item.insigniasEmitidas !== null || item.certificadosEmitidos !== null || item.carnetsEmitidos !== null
    );
    setNotifications(alertFiltered);
  };

  const handleClear = async () => {
    const { data } = await axios.put(`/alertNotifications/clear/${userId}`);

    if (data) {
      setNotifications([]);
      handleClose();
    }
  };

  const handleCredential = (item) => {
    setLoading(true);
    setOpen(true);
    setCredential(item);
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setCredential({});
  };

  const getBadges = async () => {
    let badgeLength = 0;
    const { data } = await axios.post('badgesIssued/getBadgesIssuedByUser', {
      idOrganizationBackoffice,
    });
    badgeLength = data.issued.length;
    setBadges(data.issued);
    setView(0);
    setActive('insignias');
    return badgeLength;
  };

  const getCertificates = async () => {
    let certificateLength = 0;
    const { data } = await axios.post('certificatesIssued/getCertificatesIssuedByUser', {
      idOrganizationBackoffice,
    });
    certificateLength = data.issued.length;
    setCertificates(data.issued);
    setView(1);
    setActive('certificados');
    return certificateLength;
  };

  const getCarnets = async () => {
    let carnetLength = 0;
    const { data } = await axios.post('carnetsIssued/getCarnetsIssuedByUser', {
      idOrganizationBackoffice,
    });
    carnetLength = data.issued.length;
    setCarnets(data.issued);
    setView(2);
    setActive('carnets');
    return carnetLength;
  };

  const handleAccept = () => {
    setLoadingBackdrop(true);
    if (parseInt(credential.tipo_producto) === 1) {
      axios
        .get(`/badgesIssued/accept/${credential.id_insignias}`)
        .then((res) => {
          setOpen(false);
          handleClose();
          setCredential({});
          getBadges();
          updateNotification(credential.id);
          setNotifications(notifications.filter((item) => item.id !== credential.id));
          setLoadingBackdrop(false);
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        })
        .catch((error) => {
          setOpen(false);
          handleClose();
          setCredential({});
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.deleted-error'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else if (parseInt(credential.tipo_producto) === 2) {
      axios
        .get(`/certificatesIssued/accept/${credential.id_certificados}`)
        .then((res) => {
          setOpen(false);
          handleClose();
          setCredential({});
          getCertificates();
          updateNotification(credential.id);
          setNotifications(notifications.filter((item) => item.id !== credential.id));
          setLoadingBackdrop(false);
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        })
        .catch(() => {
          setOpen(false);
          handleClose();
          setCredential({});
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.deleted-error'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else if (parseInt(credential.tipo_producto) === 3) {
      axios
        .get(`/carnetsIssued/accept/${credential.id_carnets}`)
        .then((res) => {
          setOpen(false);
          handleClose();
          setCredential({});
          getCarnets();
          updateNotification(credential.id);
          setNotifications(notifications.filter((item) => item.id !== credential.id));
          setLoadingBackdrop(false);
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        })
        .catch(() => {
          setOpen(false);
          handleClose();
          setCredential({});
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.deleted-error'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const updateNotification = async (id) => {
    const { data } = await axios.put(`/alertNotifications/${id}`, { estado: 2 });
    if (data) {
      //setNotifications([]);
      //handleClose();
    }
  };

  const handleReject = () => {
    setLoadingBackdrop(true);
    if (parseInt(credential.tipo_producto) === 1) {
      axios
        .get(`/badgesIssued/reject/${credential.id_insignias}`)
        .then((res) => {
          setOpen(false);
          handleClose();
          setCredential({});
          updateNotification(credential.id);
          setNotifications(notifications.filter((item) => item.id !== credential.id));
          setLoadingBackdrop(false);
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        })
        .catch(() => {
          setOpen(false);
          handleClose();
          setCredential({});
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.deleted-error'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else if (parseInt(credential.tipo_producto) === 2) {
      axios
        .get(`/certificatesIssued/reject/${credential.id_certificados}`)
        .then((res) => {
          setOpen(false);
          handleClose();
          setCredential({});
          updateNotification(credential.id);
          setNotifications(notifications.filter((item) => item.id !== credential.id));
          setLoadingBackdrop(false);
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            iconColor: colors.primary,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        })
        .catch(() => {
          setOpen(false);
          handleClose();
          setCredential({});
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.deleted-error'),
            icon: 'error',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else if (parseInt(credential.tipo_producto) === 3) {
      updateNotification(credential.id);
      setLoadingBackdrop(false);
      history.push(`/helpCenter/carnet/${encrypt(credential.id_carnets)}`);
    }
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="primary">
          <NotificationsNoneOutlined />
        </Badge>
      </IconButton>
      {notifications.length > 0 && (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <div className={classes.container__clear}>
            <Tooltip title={t('alerts:notifications.clear')}>
              <IconButton aria-label="delete" onClick={handleClear}>
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>

          {notifications.map((item) =>
            parseInt(item.tipo) === 1 ? (
              <MenuItem style={{ width: '27em', minHeight: '6em' }}>
                <div className={classes.container__msg}>
                  <div className={classes.container__img}>
                    <Avatar variant="square" src={item.organizationPhoto} className={classes.img_org} />
                  </div>
                  <div className={classes.container__text}>
                    <Typography style={{ whiteSpace: 'initial', fontSize: '16px' }}>
                      {`${t('alerts:notifications.organization')} ${item.organizaciones?.nombre} ${t(
                        'alerts:notifications.issued'
                      )} ${item.cant_emisiones} ${
                        parseInt(item.tipo_producto) === 1
                          ? t('alerts:notifications.badges')
                          : parseInt(item.tipo_producto) === 2
                          ? t('alerts:notifications.certificates')
                          : parseInt(item.tipo_producto) === 3
                          ? t('alerts:notifications.carnets')
                          : t('alerts:notifications.carnets')
                      }.`}
                    </Typography>
                    <Typography className={classes.date_hour}>
                      {moment(item.fecha_hora).format('DD/MM/YYYY h:mm a')}
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleCredential(item)} style={{ width: '25em', minHeight: '5em' }}>
                <div className={classes.container__msg}>
                  <div className={classes.container__img}>
                    <Avatar
                      variant="square"
                      src={
                        parseInt(item.tipo_producto) === 1
                          ? item.insigniasEmitidas?.photoBadge
                          : parseInt(item.tipo_producto) === 2
                          ? item.certificadosEmitidos?.photoCertificate
                          : parseInt(item.tipo_producto) === 3
                          ? item.carnetsEmitidos?.photoCarnet
                          : ''
                      }
                      className={
                        parseInt(credential.tipo_producto) === 2 ? classes.img_cred_certificate : classes.img_cred
                      }
                    />
                  </div>
                  <div className={classes.container__text}>
                    <Typography style={{ whiteSpace: 'initial', fontSize: '16px' }}>
                      {`${t('alerts:notifications.received')} ${
                        parseInt(item.tipo_producto) === 1
                          ? t('alerts:notifications.new-badge')
                          : parseInt(item.tipo_producto) === 2
                          ? t('alerts:notifications.new-certificate')
                          : parseInt(item.tipo_producto) === 3
                          ? t('alerts:notifications.new-carnet')
                          : t('alerts:notifications.new-carnet')
                      } ${t('alerts:notifications.from')} ${item.organizaciones?.nombre}.`}
                    </Typography>
                    <Typography className={classes.date_hour}>
                      {moment(item.fecha_hora).format('DD/MM/YYYY h:mm a')}
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            )
          )}
        </Menu>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
      >
        {loading ? (
          <div style={{ margin: '1em' }}>
            <Skeleton variant="rect" height={35} />
          </div>
        ) : (
          <DialogTitle id="max-width-dialog-title">{`${t('alerts:notifications.received')} ${
            parseInt(credential.tipo_producto) === 1
              ? t('alerts:notifications.new-badge')
              : parseInt(credential.tipo_producto) === 2
              ? t('alerts:notifications.new-certificate')
              : t('alerts:notifications.new-carnet')
          }`}</DialogTitle>
        )}
        <DialogContent>
          <div className={classes.container_center}>
            {loading ? (
              <Skeleton variant="rect" width={600} />
            ) : (
              <DialogContentText className={classes.text_message}>
                {`${t('alerts:notifications.msg-invite')} ${
                  parseInt(credential.tipo_producto) === 1
                    ? t('alerts:notifications.badge')
                    : parseInt(credential.tipo_producto) === 2
                    ? t('alerts:notifications.certificate')
                    : t('alerts:notifications.carnet')
                }`}
              </DialogContentText>
            )}
          </div>
          <div className={classes.container__img}>
            {loading ? (
              <div style={{ margin: '1em' }}>
                <Skeleton
                  variant={parseInt(credential.tipo_producto) === 1 ? 'circle' : 'rect'}
                  width={110}
                  height={110}
                />
              </div>
            ) : (
              <Avatar
                variant="square"
                src={
                  parseInt(credential.tipo_producto) === 1
                    ? credential.insigniasEmitidas?.photoBadge
                    : parseInt(credential.tipo_producto) === 2
                    ? credential.certificadosEmitidos?.photoCertificate
                    : parseInt(credential.tipo_producto) === 3
                    ? credential.carnetsEmitidos?.photoCarnet
                    : ''
                }
                className={
                  parseInt(credential.tipo_producto) === 2
                    ? classes.img_cred_dialog_certificate
                    : classes.img_cred_dialog
                }
              />
            )}
          </div>
          <br />
          <div className={classes.container_center}>
            {loading ? (
              <Skeleton variant="rect" height={60} width={200} />
            ) : (
              <>
                <DialogContentText className={classes.text_message}>
                  {t('alerts:notifications.issued-by')}
                </DialogContentText>
                <Avatar variant="square" src={credential.organizationPhoto} className={classes.img_org_dialog} />
              </>
            )}
          </div>
          <br />
          <div className={`text-center`}>
            {loading ? (
              <Skeleton variant="rect" width={800} height={50} />
            ) : (
              <>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  type="submit"
                  onClick={handleAccept}
                >
                  {parseInt(credential.tipo_producto) === 3
                    ? t('alerts:notifications.see-card')
                    : t('alerts:notifications.accept')}
                </Button>

                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={`my-2 ${classes.button}`}
                  onClick={handleReject}
                >
                  {parseInt(credential.tipo_producto) === 3
                    ? t('alerts:notifications.report-error')
                    : t('alerts:notifications.reject')}
                </Button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Backdrop loading={loadingBackdrop} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container__msg: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
    },
  },
  container__text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '-1em .5em -1em .6em',
  },
  container__img: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  date_hour: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  img_org: {
    width: theme.spacing(8),
    height: 'auto',
  },
  img_org_dialog: {
    width: 'auto',
    height: theme.spacing(5),
  },
  img_cred: {
    width: theme.spacing(5),
    height: 'auto',
  },
  img_cred_certificate: {
    height: theme.spacing(5),
    minWidth: 'auto',
  },
  img_cred_dialog: {
    width: theme.spacing(15),
    height: 'auto',
  },
  img_cred_dialog_certificate: {
    height: theme.spacing(25),
    width: 'auto',
  },
  container__clear: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
    justifyContent: 'right',
    marginRight: '1em',
  },
  text_message: {
    color: '#999999',
    fontSize: '20px',
  },
  container_center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.data_user.id,
  };
};

const mapDispatchToProps = {
  setBadges,
  setCertificates,
  setCarnets,
  setView,
  setActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
