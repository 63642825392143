import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import {
  FormatBoldOutlined,
  FormatItalicOutlined,
  FormatUnderlinedOutlined,
  StrikethroughSOutlined,
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
} from '@mui/icons-material';
import webFont from 'webfontloader';
import Swal from 'sweetalert2';
import Appbar from '../../components/Appbar';
import SketchPickerButton from '../../components/SketchPickerButton';
import Certificate from './Certificate';
import Fields from './Fields';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import api from '../../api';
import onlyNumber from '../../utils/validations/onlyNumber';
import onlyText from '../../utils/validations/onlyText';
import hexToRGB from '../../utils/hexToRGB';
import { setCertificateFields, setForm, setSaveDesign } from '../../actions';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/styles/colors';

function DesignCertificate(props) {
  const { certificateFields, setCertificateFields, form, setForm, setSaveDesign, setOpenDesignCertificate } = props;
  const classes = useStyle();
  const stageRef = useRef();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedId, selectShape] = useState(null);
  const [field, setField] = useState({
    fieldType: 41,
    exampleText: '',
    fieldName: '',
    align: 'center',
    fontColor: { hex: '#000000' },
    fontSize: '18',
    fontFamily: {
      family: 'ABeeZee',
      files: {
        italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
        regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
      },
    },
    fontStyle: {
      bold: 0,
      italic: 0,
      underline: 0,
      strikethrough: 0,
    },
    x: 50,
    y: 50,
    width: 0,
    height: 0,
  });
  const indexFieldCode = certificateFields.findIndex((item) => item.fieldName === 'certificateCode');
  const indexFieldButtonVerify = certificateFields.findIndex((item) => item.fieldName === 'buttonVerify');
  const isFieldCode = field.fieldName === 'certificateCode' ? true : false;
  const isFieldButton = field.fieldName === 'buttonVerify' ? true : false;
  const [fontFamilies, setFontFamilies] = useState(
    [...certificateFields.map((field) => field.fontFamily?.family), 'ABeeZee'] || ['ABeeZee']
  );
  const [fontList, setFontList] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [t] = useTranslation(['alerts', 'certificateBadgeTemplate', 'buttons', 'dialogs', 'selects', 'tables']);
  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    setSaveDesign(false);
    setLoading(true);

    api.get(`/others/getFieldTypes`).then(({ data }) => {
      setFieldTypes(
        data.fieldTypes
          .filter((item) => item.id !== 51 && item.id !== 52)
          .map((fieldType) => ({
            id: fieldType.id,
            value: languajeEn ? fieldType.valor_parametro_en : fieldType.valor_parametro,
          }))
      );
    });

    if (fontFamilies.length > 0) {
      webFont.load({
        google: {
          families: fontFamilies,
        },
        active: () => {
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }

    axios
      .get(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAiqPGRASVurt5YfOdCCDOljv0nqATgCSc&fields=items.family,items.files&sort=alpha'
      )
      .then(({ data }) => {
        setFontList(data.items);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e) => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setField({
      fieldType: 41,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
  };

  const changeFontStyle = (fontStyle) => {
    setField({ ...field, fontStyle });
  };

  const changeColor = (color) => {
    setField({ ...field, fontColor: color });
  };

  const changeColorButton = (color) => {
    setField({ ...field, rectColor: color });
  };

  const handleChange = (e) => {
    if (e.target.name === 'fontSize' && e.target.value > 100) return;
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const handleChangeCode = (e) => {
    if (onlyText(e)) {
      setField({ ...field, [e.target.name]: e.target.value });
    }
  };

  const onChangeFontFamily = (fontFamily) => {
    if (fontFamily?.family && !fontFamilies.includes(fontFamily.family)) {
      const fonts = [...fontFamilies, fontFamily.family];
      setFontFamilies(fonts);

      webFont.load({
        google: {
          families: [fontFamily.family],
        },
        active: () => {
          setField({ ...field, fontFamily: fontFamily });
        },
      });
    } else {
      setField({ ...field, fontFamily: fontFamily });
    }
  };

  const validateRequiredProps = () => {
    if (isFieldCode || isFieldButton) {
      return false;
    }
    if (
      !field.fieldName?.length > 0 ||
      !field.exampleText?.length > 0 ||
      !field.fontSize > 0 ||
      !field.fontFamily ||
      !field.fieldType
    ) {
      return true;
    }

    return false;
  };

  const saveField = () => {
    if (validateRequiredProps()) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.certificate-all-fields'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    if (isFieldCode) field.exampleText = field.exampleText.trim();

    const isNew = !(field.index >= 0);
    if (isNew) {
      certificateFields.push({
        ...field,
        page: form?.page,
        width: 430,
        height: field.fontSize,
      });
      selectShape(field.exampleText);
      setIsSelected(true);
    } else {
      const index = field.index;
      delete field.index;
      certificateFields[index] = field;
    }

    setCertificateFields([...certificateFields]);
    setField({
      fieldType: 41,
      exampleText: '',
      fieldName: '',
      align: 'center',
      fontColor: { hex: '#000000' },
      fontSize: '18',
      fontFamily: {
        family: 'ABeeZee',
        files: {
          italic: 'http://fonts.gstatic.com/s/abeezee/v14/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf',
          regular: 'http://fonts.gstatic.com/s/abeezee/v14/esDR31xSG-6AGleN6tKukbcHCpE.ttf',
        },
      },
      fontStyle: {
        bold: 0,
        italic: 0,
        underline: 0,
        strikethrough: 0,
      },
      x: 50,
      y: 50,
      width: 0,
      height: 0,
    });
    setOpen(false);
  };

  const modifyField = (index) => {
    if (index === -1) {
      setField({ ...certificateFields[indexFieldCode], index: indexFieldCode });
    } else if (index === -3) {
      setField({ ...certificateFields[indexFieldButtonVerify], index: indexFieldButtonVerify });
    } else {
      setField({ ...certificateFields[index], index });
    }

    setOpen(true);
  };

  const saveFields = async () => {
    const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
    Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

    const fieldsTextNumber = certificateFields.filter(
      (field) => (field.fieldType === 41 && field.fieldName !== 'certificateCode') || field.fieldType === 42
    );
    if (fieldsTextNumber.length <= 0) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.certificate-field'),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          container: classes.sweetModal,
        },
      });
    }

    const uri = stageRef?.current?.toDataURL();
    let uris = form?.img_template || [];
    if (uris.length === form?.pages) {
      uris[form?.page - 1] = uri;
    } else {
      uris.push(uri);
    }
    if (form?.pages > 1) {
      for (let i = 0; i < form?.pages; i++) {
        if (!uris[i]) {
          uris = null;
          return Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.certificate-no-design-page'),
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              container: classes.sweetModal,
            },
          });
        }
      }
    }
    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      certificateFields[indexFieldCode].exampleText = `${certificateFields[indexFieldCode].exampleText}${
        certificateFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }
    setForm({ ...form, img_template: uris });
    setSaveDesign(true);
    setOpenDesignCertificate(false);
  };

  const handleCancel = () => {
    const updatedFonts = document.querySelectorAll('link[media="all"][href^="https://fonts.googleapis.com/css"]');
    Array.prototype.forEach.call(updatedFonts, (updatedFont) => updatedFont.remove());

    if (indexFieldCode !== undefined && indexFieldCode !== -1) {
      certificateFields[indexFieldCode].exampleText = `${certificateFields[indexFieldCode].exampleText}${
        certificateFields[indexFieldCode].exampleText === '' ? '' : ':'
      }CK09aABbAz`;
    }

    const uri = stageRef?.current?.toDataURL();
    const uris = form?.img_template || [];
    if (uris.length === form?.pages) {
      uris[form?.page - 1] = uri;
      setForm({ ...form, img_template: uris });
    } else {
      delete form.img_template;
      setForm({ ...form });
    }
    setOpenDesignCertificate(false);
  };

  const disabledCertificateCode = () => {
    return isFieldCode;
  };

  const validateBGColor = (hex) => {
    const rgb = hexToRGB(hex);
    return rgb.r > 230 && rgb.g > 230 && rgb.b > 230 ? classes.dialog__title : '';
  };

  if (loading) return <></>;

  return (
    <>
      <Appbar />
      <div className={classes.container}>
        <div className={classes.container__certificate}>
          <Certificate
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            selectedId={selectedId}
            selectShape={selectShape}
            stageRef={stageRef}
            modifyField={modifyField}
            form={form}
            setForm={setForm}
          />
        </div>
        <div className={classes.container__fields}>
          <Fields openModal={openModal} modifyField={modifyField} selectShape={selectShape} form={form} />
          <div className={classes.container__button_fields}>
            <Button onClick={saveFields}>{t('buttons:buttons.accept')}</Button>
            <Button onClick={handleCancel}>{t('buttons:buttons.cancel')}</Button>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={closeModal} fullWidth={true} maxWidth="sm">
        <DialogTitle
          align="center"
          className={!isFieldButton && validateBGColor(field.fontColor?.hex)}
          style={isFieldButton ? { backgroundColor: field?.rectColor?.hex } : {}}
        >
          <Typography
            style={{
              fontSize: `${field.fontSize || 18}px`,
              color: field.fontColor?.hex,
              fontFamily: field.fontFamily?.family,
              fontWeight: field.fontStyle?.bold ? 'bold' : '',
              fontStyle: field.fontStyle?.italic ? 'italic' : '',
              textDecoration: `${field.fontStyle?.underline ? 'underline' : ''} ${
                field.fontStyle?.strikethrough ? 'line-through' : ''
              }`,
              textAlign: field.align,
            }}
          >
            {isFieldCode
              ? `${field.exampleText}${field.exampleText === '' ? '' : ':'}CK09aABbAz`
              : isFieldButton
              ? 'Verificar'
              : field.exampleText || t('certificateBadgeTemplate:certificate-design.example')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers className={isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.name-field')}*</p>
            <input
              type="text"
              name="fieldName"
              value={field.fieldName || ''}
              onChange={handleChange}
              disabled={disabledCertificateCode()}
              style={{borderRadius: "16px", border: "1px solid #C4C4C4", paddingLeft: "1em"}}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.type-field')}*</p>
            <FormControl required variant="outlined" className="w-100" size="small">
              <Select
                name="fieldType"
                value={field.fieldType}
                onChange={handleChange}
                disabled={disabledCertificateCode()}
              >
                {fieldTypes.map(
                  (fieldType, index) =>
                    fieldType.id !== 48 && (
                      <MenuItem key={`field-type-option${index}`} value={fieldType.id}>
                        <em>{fieldType.value}</em>
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            <p>{t('certificateBadgeTemplate:certificate-design.example')}*</p>
            {field.fieldType === 42 && (
              <input
                type="number"
                name="exampleText"
                value={field.exampleText || ''}
                onChange={handleChange}
                onKeyUp={onlyNumber}
                style={{borderRadius: "16px", border: "1px solid #C4C4C4"}}
              />
            )}
            {field.fieldType !== 42 && (
              <OutlinedInput
                type="text"
                name="exampleText"
                value={field.exampleText || ''}
                style={{borderRadius: "16px"}}
                onChange={isFieldCode ? handleChangeCode : handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    {isFieldCode ? `${field.exampleText === '' ? '' : ':'}CK09aABbAz` : ''}
                  </InputAdornment>
                }
              />
            )}
            <p>{t('certificateBadgeTemplate:certificate-design.align-letter')}*</p>
            <div>
              <Checkbox
                icon={<FormatAlignCenterOutlined />}
                checkedIcon={<FormatAlignCenterOutlined />}
                name="align"
                value="center"
                checked={field.align === 'center'}
                onChange={handleChange}
              />
              <Checkbox
                icon={<FormatAlignLeftOutlined />}
                checkedIcon={<FormatAlignLeftOutlined />}
                name="align"
                value="left"
                checked={field?.align === 'left'}
                onChange={handleChange}
              />
              <Checkbox
                icon={<FormatAlignRightOutlined />}
                checkedIcon={<FormatAlignRightOutlined />}
                name="align"
                value="right"
                checked={field?.align === 'right'}
                onChange={handleChange}
              />
            </div>
            <p>{t('certificateBadgeTemplate:certificate-design.font')}*</p>
            <Dropdown
              name="fontFamily"
              value={field.fontFamily || null}
              placeholder="Selecciona un tipo de letra"
              options={fontList}
              getOptionLabel={(option) => option.family}
              getOptionSelected={(option) => option.family}
              onChange={(e, value) => onChangeFontFamily(value)}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.size-letter')}*</p>
            <input
              type="number"
              name="fontSize"
              value={field.fontSize || ''}
              onChange={handleChange}
              onKeyUp={onlyNumber}
              style={{borderRadius: "16px", border: "1px solid #C4C4C4", paddingLeft: "1em"}}
            />
            <p>{t('certificateBadgeTemplate:certificate-design.style-letter')}*</p>
            <div>
              <Checkbox
                icon={<FormatBoldOutlined />}
                checkedIcon={<FormatBoldOutlined />}
                name="bold"
                checked={field?.fontStyle?.bold}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, bold: e.target.checked })}
              />
              <Checkbox
                icon={<FormatItalicOutlined />}
                checkedIcon={<FormatItalicOutlined />}
                name="italic"
                checked={field?.fontStyle?.italic}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, italic: e.target.checked })}
              />
              <Checkbox
                icon={<FormatUnderlinedOutlined />}
                checkedIcon={<FormatUnderlinedOutlined />}
                name="underline"
                checked={field?.fontStyle?.underline}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, underline: e.target.checked })}
              />
              <Checkbox
                icon={<StrikethroughSOutlined />}
                checkedIcon={<StrikethroughSOutlined />}
                name="strikethrough"
                checked={field?.fontStyle?.strikethrough}
                onChange={(e) => changeFontStyle({ ...field.fontStyle, strikethrough: e.target.checked })}
              />
            </div>
            <p>{t('certificateBadgeTemplate:certificate-design.colour')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
          </div>
        </DialogContent>
        <DialogContent dividers className={!isFieldButton && classes.fieldButtonDisabled}>
          <div className={classes.content_modal}>
            <p>{t('certificateBadgeTemplate:certificate-design.colour-text')}*</p>
            <SketchPickerButton color={field.fontColor?.hex || '#000000'} changeColor={changeColor} />
            <p>{t('certificateBadgeTemplate:certificate-design.colour-background')}*</p>
            <SketchPickerButton color={field.rectColor?.hex || '#000000'} changeColor={changeColorButton} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={saveField}>
            {t('buttons:buttons.save')}
          </Button>
          <Button color="primary" variant="contained" onClick={closeModal}>
            {t('buttons:buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    backgroundColor: '#E5E5E5',
    [theme.breakpoints.up('xl')]: {
      height: '100vh',
    },
  },
  container__certificate: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  container__fields: {
    backgroundColor: 'white',
  },
  container__button_fields: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  content_modal: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '',
  },
  dialog__title: {
    backgroundColor: theme.palette.primary.main,
  },
  sweetModal: {
    zIndex: 1300,
  },
  fieldButtonDisabled: {
    display: 'none',
  },
}));

const mapStateToProps = (state) => {
  return {
    certificateFields: state.certificateFields || [],
    form: state.form,
  };
};

const mapDispatchToProps = {
  setCertificateFields,
  setForm,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignCertificate);
