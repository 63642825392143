import React, { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import logoCK from '../assets/img/Certika-435x149.png'; // cargando imagen logo
import logoMinC from '../assets/img/MinCiencias-435x149.png'; // cargando imagen logo
import qrCK from '../assets/img/qr-certika-600x600.png'; // cargando imagen qr
import qrMinC from '../assets/img/qr-minciencias-600x600.png'; // cargando imagen qr
import buttonVerify from '../assets/img/button-verify.png'; // cargando imagen buttonVerify
import buttonVerifyMinC from '../assets/img/button-verify-MinC.png'; // cargando imagen buttonVerify

const FieldImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  layoutWidth,
  layoutHeight,
  isDraggable,
  handleOnDragStart,
  modifyField,
  index,
  documentsFields,
  setDocumentsFields,
}) => {
  const img = () => {
    if (shapeProps.fieldName === 'qr') {
      return JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias' ? qrMinC : qrCK;
    } else if (shapeProps.fieldName === 'logo') {
      return JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias' ? logoMinC : logoCK;
    } else if (shapeProps.fieldName === 'buttonVerify') {
      return JSON.parse(window.localStorage.state)?.styles?.organizationName === 'Minciencias'
        ? buttonVerifyMinC
        : buttonVerify;
    } else if (shapeProps.img) {
      return shapeProps.img;
    } else {
      return shapeProps.exampleText;
    }
  };
  const [image] = useImage(img(), '');
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={image}
        onClick={onSelect}
        ref={shapeRef}
        {...shapeProps}
        onDragStart={handleOnDragStart}
        draggable={isDraggable}
        dragBoundFunc={(pos, e) => {
          let newX = pos.x;
          let newY = pos.y;
          if (newX < 0) newX = 0;
          if (newY < 0) newY = 0;
          if (newX > layoutWidth - shapeProps.width) newX = layoutWidth - shapeProps.width;
          if (newY > layoutHeight - shapeProps.height) newY = layoutHeight - shapeProps.height;

          return {
            x: newX,
            y: newY,
          };
        }}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: Math.round(e.target.x()),
            y: Math.round(e.target.y()),
            transform: false,
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          node.width(Math.max(5, node.width() * scaleX));
          node.height(
            shapeProps.fieldName === 'qr' ? Math.max(node.width() * scaleY) : Math.max(node.height() * scaleY)
          );

          onChange({
            ...shapeProps,
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            fontSize: 0,
            // set minimal value
            width: Math.round(node.width()),
            height: shapeProps.fieldName === 'qr' ? Math.round(node.width()) : Math.round(node.height()),
            transform: true,
          });
        }}
        onDblClick={(e) => {
          if (shapeProps.img) {
            modifyField(index);
          }
        }}
      />
      {isSelected && shapeProps.fieldName === 'qr' && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const minWidthHeight = 50;
            if (newBox.width <= minWidthHeight || newBox.height <= minWidthHeight) {
              return oldBox;
            }
            const maxWidthHeight = 200;
            if (newBox.width >= maxWidthHeight || newBox.height >= maxWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {isSelected && shapeProps.fieldName !== 'qr' && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const minWidthHeight = 30;
            if (newBox.width <= minWidthHeight || newBox.height <= minWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {isSelected && shapeProps.fieldName === 'logo' && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const minWidthHeight = 30;
            if (newBox.width <= minWidthHeight || newBox.height <= minWidthHeight) {
              return oldBox;
            }
            const maxWidthHeight = 500;
            if (newBox.width >= maxWidthHeight || newBox.height >= maxWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {isSelected && shapeProps.fieldName === 'buttonVerify' && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const minWidthHeight = 25;
            if (newBox.width <= minWidthHeight || newBox.height <= minWidthHeight) {
              return oldBox;
            }
            const maxWidthHeight = 150;
            if (newBox.width >= maxWidthHeight || newBox.height >= maxWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {isSelected && shapeProps.fieldName.includes('signature') && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            const iconDelete = documentsFields.find((item) => item.fieldName === 'deleteSign');
            iconDelete &&
              setDocumentsFields([
                ...documentsFields.filter((item) => item.fieldName !== 'deleteSign' && item.fieldName !== 'addSign'),
              ]);
            // limit resize
            const minWidthHeight = 50;
            if (newBox.width <= minWidthHeight || newBox.height <= minWidthHeight) {
              return oldBox;
            }
            const maxWidthHeight = 500;
            if (newBox.width >= maxWidthHeight || newBox.height >= maxWidthHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default FieldImage;
