import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import axiosFile from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Select,
  MenuItem,
  CardActions,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Slide,
  Box,
} from '@material-ui/core';
import { CardMembershipOutlined, CloseOutlined } from '@mui/icons-material';
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import ModuleHeader from '../../../components/ModuleHeader';
import Backdrop from '../../../components/Backdrop';
import colors from '../../../assets/styles/colors';
import axios from '../../../api';
import { setForm, changeBreadcrump, setCertificateFields, setSaveDesign } from '../../../actions';
import { decrypt } from '../../../utils/crypt';
import defaultFields from '../../../utils/defaultFields';
import { env } from '../../../config/environment';
import onlyNumber from '../../../utils/validations/onlyNumber';
import { useTranslation } from 'react-i18next';
import CertificateDesing from '../DesignCertificate';
import capitalize from '../../../utils/capitalize';

function CreateTemplate(props) {
  const {
    user,
    organizationId,
    form,
    returnRoute,
    setForm,
    certificateFields,
    setCertificateFields,
    changeBreadcrump,
    saveDesign,
    setSaveDesign,
  } = props;
  const history = useHistory();
  const classes = useStyles({ form });
  const imageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [costs, setCosts] = useState([]);
  const [time, setTime] = useState([]);
  const [typeCriteria, setTypeCriteria] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [certificateTemplates, setCertificateTemplates] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [badgesIssued, setBadgesIssued] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDesignCertificate, setOpenDesignCertificate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [skillValue, setSkillValue] = useState({});
  const isModifying = props.match.params.id ? true : false;

  const [t] = useTranslation([
    'alerts',
    'certificateBadgeTemplate',
    'buttons',
    'dialogs',
    'selects',
    'breadCrumps',
    'tables',
  ]);

  const languajeEn = window.localStorage.state?.split('"lenguage":"')[1]?.slice(0, 2) === 'en';

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      await getSkillsOptions();
      await getTypes();
      await getLeves();
      await getCosts();
      await getTime();
      await getTypeCriteria();
      await getOrganizations();

      if (isModifying) {
        await getCertificateTemplate();
        changeBreadcrump(t('breadCrumps:breadCrumps.certificate-template-edit', { returnObjects: true }));
        setSaveDesign(true);
      } else {
        setForm({
          ...form,
          img_prev: form?.img_prev && form?.img_prev[0]?.split(':', 1)[0] === 'blob' ? null : form.img_prev,
          id_organizaciones:
            user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ? organizationId : '',
          id_usuarios: user.data_user?.id,
          page: 1,
        });
        changeBreadcrump(t('breadCrumps:breadCrumps.certificate-template-create', { returnObjects: true }));
      }
      setLoading(false);
    } catch (error) {
      history.push('/500');
    }
  };

  useEffect(() => {
    getCertificateTemplates(form.id_organizaciones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.id_organizaciones]);

  const isSuper = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ? true : false;

  const getSkillsOptions = async () => {
    const { data } = await axios.post(`/hability/getHabilities`);
    setSkillsOptions(data.habilities.rows);
  };

  const getTypes = async () => {
    const { data } = await axios.post(`/others/getTypes`);
    setTypes(data.types);
  };

  const getLeves = async () => {
    const { data } = await axios.post(`/others/getLevels`);
    setLevels(data.levels);
  };

  const getCosts = async () => {
    const { data } = await axios.post(`/others/getCosts`);
    setCosts(data.costs);
  };

  const getTime = async () => {
    const { data } = await axios.post(`/others/getTime`);
    setTime(data.time);
  };

  const getTypeCriteria = async () => {
    const { data } = await axios.post(`/others/getTypeCriteria`);
    setTypeCriteria(data.typeCriteria);
  };

  const getCertificateTemplates = async (id_organizaciones) => {
    const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished/names`, {
      id_organizaciones,
    });
    const isModifying = props.match.params.id ? true : false;
    const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;

    if (!isModifying) {
      if (isDeparmentCreater) {
        setCertificateTemplates(
          data.certificateTemplates.filter(
            (templateBadge) => templateBadge.plantillasCertificadosHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setCertificateTemplates(data.certificateTemplates);
      }
    } else {
      const id = parseInt(decrypt(props.match.params.id), 10);
      if (isDeparmentCreater) {
        setCertificateTemplates(
          data.certificateTemplates.filter(
            (certificateTemplates) =>
              certificateTemplates.id !== id &&
              certificateTemplates.plantillasCertificadosHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setCertificateTemplates(
          data.certificateTemplates.filter((certificateTemplates) => certificateTemplates.id !== id)
        );
      }
    }
  };

  const getOrganizations = async () => {
    if (isSuper) {
      const { data } = await axios.post(`/organization/getOrganizations`);
      setOrganizations(data.organizations.rows);
    } else {
      const { data } = await axios.get(`/organization/${organizationId}`);
      setOrganizations(data.organization.organizations);
    }
  };

  const getCertificateTemplate = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/certificateTemplate/${id}`);
      const {
        certificateTemplate,
        criterion,
        skillsTemplate,
        linkPhoto,
        linkPhotoTemplate,
        certificateFieldsAdditionals,
        certificateModules,
      } = data.certificateTemplate;
      setBadgesIssued(data.certificateTemplate?.certificateTemplate?.certificadosEmitidos);
      setCertificateFields(
        data.certificateTemplate?.certificateTemplate?.certificadoCampos.map((field) => ({
          id: field.id,
          fieldType: field.tipo_campo_vp,
          exampleText: field.texto_ejemplo,
          fieldName: field.nombre,
          align: field.formato_letra ? field.formato_letra : 'center',
          fontColor: { hex: field.color },
          rectColor: { hex: field.background_color },
          fontSize: field.tamano_letra,
          fontFamily: { family: field.tipo_letra },
          fontStyle: {
            bold: field.bold,
            italic: field.italic,
            underline: field.underline,
            strikethrough: field.strikethrough,
          },
          x: parseFloat(field.x),
          y: parseFloat(field.y),
          width: field.width === 0 ? 430 : field.width,
          height: field.height === 0 ? field.tamano_letra : field.height,
          page: field.page,
        }))
      );
      delete data.certificateTemplate?.certificateTemplate?.certificadoCampos;
      setForm({
        ...certificateTemplate,
        id_plantillas_recomendadas: data.certificateTemplate?.recommendations.map((item) => ({
          id: item.id,
          nombre: item.plantillasCertificadosRecomendadas.nombre,
          nombre_privado: item.plantillasCertificadosRecomendadas.nombre_privado,
          id_plantillas_certificados: item.id_plantillas_certificados,
          id_plantillas_certificados_recomendadas: item.id_plantillas_certificados_recomendadas,
        })),
        img_prev: linkPhoto,
        img_template: linkPhotoTemplate,
        id_usuarios: user.data_user?.id,
        skills: skillsTemplate.map((item, index) => ({
          index,
          id: item.id,
          id_habilidades: item.id_habilidades,
          id_plantillas_certificados: item.id_plantillas,
          descripcion: item.habilidades.descripcion,
        })),
        activities: criterion.map((item, index) => ({
          index,
          id: item.id,
          tipo_criterio_vp: item.tipo_criterio_vp,
          descripcion_criterio: item.descripcion_criterio,
          link: item.link,
        })),
        fieldsAdditionals: certificateFieldsAdditionals.map((item, index) => ({
          index,
          id: item.id,
          titulo_campo: item.titulo_campo,
          descripcion_campo: item.descripcion_campo,
          link: item.link,
        })),
        modules: certificateModules.map((item, index) => ({
          index,
          id: item.id,
          titulo_modulo: item.titulo_modulo,
          descripcion_modulo: item.descripcion_modulo,
          fecha_inicio: item.fecha_inicio ? item.fecha_inicio.split(' ')[0] : '',
          fecha_fin: item.fecha_fin ? item.fecha_fin.split(' ')[0] : '',
          sessions: item.sessions.map((session) => ({
            ...session,
            id_modulo: index,
            fecha_inicio: session.fecha_inicio.split(' ')[0],
            fecha_fin: session.fecha_fin.split(' ')[0],
          })),
        })),
        estado: certificateTemplate.estado,
        page: 1,
      });
    } catch (error) {
      history.push('/500');
    }
  };

  const nombrePrivado = () => {
    if (badgesIssued !== 0 && props.match.params.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputCriteria = (event, index) => {
    form.activities?.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setForm({ ...form, activities: form.activities });
  };

  const handleRecommendation = (event, values) => {
    setForm({
      ...form,
      id_plantillas_recomendadas: values,
    });
  };

  const handleCancel = () => {
    setForm(null);
    setCertificateFields(null);

    history.push(returnRoute);
  };

  const handleFile = (e) => {
    const imagen = e.target.files;
    if (imagen.length > 3) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.max-template-certificate-alert'),
        showConfirmButton: true,
        confirmButtonText: t('buttons:buttons.accept'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
        },
      });
    }
    if (imagen.length > 0) {
      let uris = [];
      let elementImg = [];
      for (let i = 0; i < imagen.length; i++) {
        let url = URL.createObjectURL(imagen[i]);
        uris.push(url);
        let img = document.createElement('img');
        img.src = url;
        elementImg.push(img);
      }
      setImage({ imagen: imagen, elements: elementImg });
      setForm({
        ...form,
        img_prev: uris,
        img_template: null,
        imagen: 'newImage',
        pages: uris.length,
        page: 1,
      });
      certificateFields && setCertificateFields(certificateFields.map((field) => ({ ...field, x: 50, y: 50 })));
      setSaveDesign(false);
    }
  };

  const addActivity = () => {
    const last = form.activities[form.activities?.length - 1];
    setForm({ ...form, activities: [...form.activities, { index: last.index + 1 }] });
  };

  const deleteActivity = (e, index) => {
    let criterionDeleted;
    if (form.activities.length === 0) {
      criterionDeleted = [{ index: 1 }];
    } else {
      criterionDeleted = form.activities?.filter((item) => item.index !== index);
    }
    setForm({ ...form, activities: criterionDeleted });
  };

  const addFieldAdditional = () => {
    const last = form.fieldsAdditionals[form.fieldsAdditionals?.length - 1];
    setForm({ ...form, fieldsAdditionals: [...form.fieldsAdditionals, { index: last.index + 1 }] });
  };

  const deleteFieldAdditional = (e, index) => {
    let fieldAdditionalDeleted;
    if (form.fieldsAdditionals.length === 0) {
      fieldAdditionalDeleted = [{ index: 1 }];
    } else {
      fieldAdditionalDeleted = form.fieldsAdditionals?.filter((item) => item.index !== index);
    }
    setForm({ ...form, fieldsAdditionals: fieldAdditionalDeleted });
  };

  const handleInputFieldAdditional = (event, index) => {
    form.fieldsAdditionals?.map((item) => {
      if (item.index === index) {
        item[event.target.name] = event.target.value;
      }
      return item;
    });
    setForm({ ...form, fieldsAdditionals: form.fieldsAdditionals });
  };

  const privateName = (template) => {
    return template.nombre_privado !== '' ? `${template.nombre} (${template.nombre_privado})` : template.nombre;
  };

  const cantidadTiempo = (cantidad) => {
    if (cantidad === '' || cantidad < 1) {
      form.cantidad_tiempo = null;
      return form.cantidad_tiempo;
    } else {
      return cantidad;
    }
  };

  const requiredCantidadTiempo = (cantidad) => {
    if (cantidad > 1) {
      return false;
    } else {
      form.cantidad_tiempo = null;
      return true;
    }
  };

  const goToDesignCertificate = async () => {
    const haveUploadedAnImage = form.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const md5OrganizationRoute = md5(`certika-organization-${organizationId}`).toString();
    const isANewImage = image && image?.imagen[0]?.name ? true : false;
    const isBlod = form?.img_prev[0]?.split(':', 1)[0] === 'blob' ? true : false;
    const isBlodHorizontal = imageRef.current?.naturalWidth > imageRef.current?.naturalHeight ? true : false;
    const isHorizontal = form.width > form.height ? true : false;
    if (isANewImage) {
      if (
        isBlod &&
        isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 950 ||
          imageRef.current?.naturalHeight > 700 ||
          imageRef.current?.naturalWidth < 750 ||
          imageRef.current?.naturalHeight < 550)
      ) {
        return Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
      if (
        isBlod &&
        !isBlodHorizontal &&
        (imageRef.current?.naturalWidth > 668 ||
          imageRef.current?.naturalHeight > 900 ||
          imageRef.current?.naturalWidth < 420 ||
          imageRef.current?.naturalHeight < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
      if (!isBlod && isHorizontal && (form.width > 950 || form.height > 700 || form.width < 750 || form.height < 550)) {
        return Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.horizontal-certificates'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }
      if (
        !isBlod &&
        !isHorizontal &&
        (form.width > 668 || form.height > 900 || form.width < 420 || form.height < 690)
      ) {
        return Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.vertical-certificates'),
          showConfirmButton: true,
          confirmButtonText: t('buttons:buttons.accept'),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'custom-button-confirm',
          },
        });
      }

      for (let i = 1; i < form.img_prev.length; i++) {
        if (
          image?.elements[0]?.naturalWidth !== image?.elements[i]?.naturalWidth ||
          image?.elements[0]?.naturalHeight !== image?.elements[i]?.naturalHeight
        ) {
          return Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.no-equals-format'),
            showConfirmButton: true,
            confirmButtonText: t('buttons:buttons.accept'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          });
        }
      }
      setLoading(true);

      for (let i = 0; i < form.img_prev.length; i++) {
        const certificateImageForm = new FormData();
        certificateImageForm.append('file', image?.imagen[i]);
        certificateImageForm.append(
          'route',
          `${md5OrganizationRoute}/certificateTemplate/certificate-temp/temp${form?.pages > 1 ? i : ''}`
        );

        await axiosFile({
          method: 'post',
          url: `${env.apiURL}/file/upload-certificate`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: certificateImageForm,
        });
      }

      if (!certificateFields?.length > 0) {
        const { dataQr, dataImg, dataCode, dataButtonVerify } = defaultFields();
        setCertificateFields([dataQr, dataImg, dataCode, dataButtonVerify]);
      }

      let urls = [];
      for (let i = 0; i < form.img_prev.length; i++) {
        const { data } = await axios.post('/file/getFile', {
          keyFile: `${md5OrganizationRoute}/certificateTemplate/certificate-temp/temp${form?.pages > 1 ? i : ''}`,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        urls.push(data?.url);
      }

      setForm({
        ...form,
        img_prev: urls,
        width: imageRef.current?.naturalWidth,
        height: imageRef.current?.naturalHeight,
      });
    }

    const indexField = certificateFields?.findIndex((item) => item.fieldName === 'certificateCode');
    if (indexField !== undefined && indexField !== -1) {
      certificateFields[indexField].exampleText = certificateFields[indexField].exampleText.includes(':')
        ? certificateFields[indexField].exampleText.split(':')[0]
        : '';
    }
    setOpenDesignCertificate(true);
    setLoading(false);
  };

  const saveTemplate = async (e) => {
    e.preventDefault();

    const haveUploadedAnImage = form.img_prev ? true : false;
    if (!haveUploadedAnImage) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.template-certificate-alert'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const certificateDesigned = certificateFields?.length > 0;
    if (!certificateDesigned) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!saveDesign) {
      return Swal.fire({
        icon: 'info',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-accepted-design-certificate'),
        showConfirmButton: false,
        timer: 1500,
      });
    }
    let validDateModule = {};

    // eslint-disable-next-line
    form.modules.map((modulo) => {
      if (modulo.fecha_inicio && modulo.fecha_fin && modulo.fecha_inicio > modulo.fecha_fin) {
        validDateModule = { valid: true, modulo: modulo.index + 1 };
      }
    });

    if (validDateModule.valid) {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: `La fecha de inicio del módulo ${validDateModule.modulo} debe ser menor a la fecha de finalización`,
        showConfirmButton: false,
        timer: 3000,
      });
    }

    const isANewTemplate = !form.id ? true : false;
    setLoading(true);
    delete form.img_template;
    try {
      if (isANewTemplate) {
        await axios.post(`/certificateTemplate`, {
          ...form,
          criterios: form.activities,
          id_habilidades: form.skills,
          camposAdicionales: form.fieldsAdditionals,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          certificateFields,
        });
      } else {
        await axios.put(`/certificateTemplate/${form.id}`, {
          ...form,
          criterios: form.activities,
          id_habilidades: form.skills,
          camposAdicionales: form.fieldsAdditionals,
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
          certificateFields,
        });
      }
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.error-ocurred'),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setLoading(false);
    Swal.fire({
      icon: 'success',
      iconColor: colors.primary,
      text: t('alerts:alerts.saved'),
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      if (isANewTemplate) {
        history.push('/certificates/edited');
      } else {
        history.push(returnRoute);
      }
      setForm(null);
      setCertificateFields(null);
    });
  };

  const loadFillDataTypes = () => {
    return types.map((data) => (
      <MenuItem key={`type-${data.id}`} value={data.id}>
        {languajeEn ? data.valor_parametro_en : data.valor_parametro}
      </MenuItem>
    ));
  };

  const loadFillDataLevels = () => {
    return levels.map((data) => {
      return (
        <MenuItem key={`level-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataCosts = () => {
    return costs.map((data) => {
      return (
        <MenuItem key={`cost-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTime = () => {
    return time.map((data) => {
      return (
        <MenuItem key={`time-${data.id}`} value={data.id}>
          {languajeEn ? data.valor_parametro_en : data.valor_parametro}
        </MenuItem>
      );
    });
  };

  const loadFillDataTypeCriteria = () => {
    return typeCriteria.map((data) => (
      <MenuItem key={`type-criteria-${data.id}`} value={data.id}>
        {languajeEn ? data.valor_parametro_en : data.valor_parametro}
      </MenuItem>
    ));
  };

  const loadFillDataOrganization = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={`organization-${data.id}`} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const loadFillDataOrganizationsRelations = () => {
    const arrayOrganizations = organizations?.organizacionesRelaciones.map((data) => data.organizacionesRelacionadas);

    return arrayOrganizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSkill = (e, newSkill) => {
    setForm({ ...form, skills: [...form.skills, newSkill.pop()] });
    setSkillsOptions(skillsOptions.filter((item) => item.descripcion.trim() !== e.target.innerText));
  };

  const deleteSkill = (hability) => {
    setForm({
      ...form,
      skills: form?.skills.filter((item) => item !== hability),
    });
    if (hability.id_habilidades) {
      setSkillsOptions([...skillsOptions, hability]);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChip = (skill, index) => {
    if (typeof skill === 'string') {
      setOpenEdit(true);
      setSkillValue({ value: skill, index: index });
    }
  };

  const editSkill = () => {
    form?.skills?.splice(skillValue.index, 1, skillValue.value);
    setOpenEdit(false);
  };

  const handleChangePage = (event, value) => {
    setForm({
      ...form,
      page: value,
    });
  };

  return (
    <>
      <ModuleHeader create="false" />
      <Card className="m-0 w-100 p-0 border-0" elevation={0}>
        <CardContent className="p-3">
          <form className={classes.root} onSubmit={saveTemplate}>
            <Typography component="h6" variant="h6">
              {t('certificateBadgeTemplate:badges-certificate-form.details-certificate')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <div className="avatar-icon-wrapper avatar-icon-xl" style={{ alignSelf: 'center' }}>
                  <Tooltip title={t('tables:table.tooltip.view-template')}>
                    <Avatar
                      imgProps={{ ref: imageRef }}
                      alt="img-prev"
                      src={
                        form?.img_template
                          ? form?.img_template[form?.page - 1]
                          : form?.img_prev
                          ? form?.img_prev[form?.page - 1]
                          : null
                      }
                      variant="rounded"
                      className={classes.avatar}
                      onClick={() => setOpen(form?.img_prev ? true : false)}
                    >
                      <CardMembershipOutlined sx={{ margin: 4, fontSize: '4em' }} />
                    </Avatar>
                  </Tooltip>
                  <Box component="span" display="flex" justifyContent="center">
                    <Pagination
                      color="secondary"
                      count={form?.pages || 0}
                      page={form?.page || 1}
                      onChange={handleChangePage}
                      size="small"
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body2">
                  {t('certificateBadgeTemplate:badges-certificate-form.certificate-img')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.certificate-img-max-file')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.recommended-size')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.horizontal')}</b>
                  {t('certificateBadgeTemplate:badges-certificate-form.least')}
                  <b>950 x 660 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')}
                  <br />
                  {t('certificateBadgeTemplate:badges-certificate-form.certificates')}
                  <b>{t('certificateBadgeTemplate:badges-certificate-form.vertical')} 668 x 890 </b>
                  {t('certificateBadgeTemplate:badges-certificate-form.pixels')}
                </Typography>
                <div className={classes.contentInput}>
                  <input
                    multiple
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    name="imagen"
                    type="file"
                    onChange={(e) => handleFile(e)}
                    disabled={nombrePrivado()}
                  />
                  <Grid container spacing={2}>
                    <Grid item>
                      <label htmlFor="contained-button-file">
                        <Button variant="outlined" color="primary" component="span" disabled={nombrePrivado()}>
                          {t('buttons:buttons.upload-image')}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        disabled={nombrePrivado()}
                        onClick={goToDesignCertificate}
                      >
                        {t('buttons:buttons.design-certificate')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.certificate-public-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="nombre"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.certificate-private-name')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    required
                    label=""
                    name="nombre_privado"
                    variant="outlined"
                    size="small"
                    onChange={handleInput}
                    value={form?.nombre_privado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.description')}*
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    required
                    label=""
                    name="descripcion"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    value={form?.descripcion}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.additional')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl className="w-100">
                  <TextField
                    disabled={nombrePrivado()}
                    label=""
                    name="url"
                    variant="outlined"
                    size="small"
                    placeholder="http://"
                    onChange={handleInput}
                    value={form?.url}
                    type="url"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" className={classes.text} align="right">
                  {t('certificateBadgeTemplate:badges-certificate-form.skills')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl>
                  <Autocomplete
                    disabled={nombrePrivado()}
                    multiple
                    disableClearable
                    freeSolo
                    className={classes.autocomplete}
                    onChange={(e, newSkill) => handleSkill(e, newSkill)}
                    options={skillsOptions
                      .sort((a, b) => (a.descripcion.trim() < b.descripcion.trim() ? -1 : 1))
                      .map((skill) => ({
                        id_habilidades: skill.id,
                        descripcion: skill.descripcion,
                      }))}
                    getOptionLabel={(option) => option.descripcion}
                    renderTags={() => null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        variant="outlined"
                        label={t('selects:selects-autocompletes.skill-select')}
                      />
                    )}
                  />
                </FormControl>
                {form?.skills?.length > 0 ? (
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      {form.skills.map((hability, index) => (
                        <Grid key={`skill-chip-${index}`}>
                          <Chip
                            disabled={nombrePrivado()}
                            onDelete={() => deleteSkill(hability)}
                            onClick={() => handleChip(hability, index)}
                            variant="outlined"
                            label={
                              <Typography className={classes.skills}>
                                {typeof hability === 'string' ? hability : hability.descripcion}
                              </Typography>
                            }
                            className={classes.chip}
                          />
                        </Grid>
                      ))}
                    </CardContent>
                  </Card>
                ) : (
                  ''
                )}
              </Grid>
              {user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        id="organizacion"
                        name="id_organizaciones"
                        value={form.id_organizaciones || ''}
                        disabled={nombrePrivado()}
                        onChange={handleInput}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganization()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {organizations?.organizacionesRelaciones?.length > 0 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="body1" className={classes.text} align="right">
                      {t('certificateBadgeTemplate:badges-certificate-form.organization')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <FormControl required variant="outlined" className="w-100" size="small">
                      <Select
                        disabled={nombrePrivado()}
                        id="organizacion"
                        name="id_organizacion_hija"
                        onChange={handleInput}
                        value={form.id_organizacion_hija}
                      >
                        <MenuItem value="">
                          <em>{t('selects:selects-autocompletes.select-option')}</em>
                        </MenuItem>
                        {loadFillDataOrganizationsRelations()}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.criteria-certificate-title')}
            </Typography>
            {form.activities.map((item, index) => (
              <Grid container key={`grid-activity-${index}`} spacing={2} className={classes.root}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1" className={classes.text} align="right">
                    {`${t('certificateBadgeTemplate:badges-certificate-form.criterion')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography variant="body1" align="left">
                        {`${t('certificateBadgeTemplate:badges-certificate-form.type')}${
                          item.descripcion_criterio ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl variant="outlined" className={`w-100 ${classes.fieldCard}`} size="small">
                        <Select
                          name="tipo_criterio_vp"
                          value={item.tipo_criterio_vp || ''}
                          required={item.descripcion_criterio ? true : false}
                          disabled={nombrePrivado()}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                        >
                          <MenuItem value="">
                            <em>{t('selects:selects-autocompletes.select-option')}</em>
                          </MenuItem>

                          {loadFillDataTypeCriteria()}
                        </Select>
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.description')}${
                          item.tipo_criterio_vp ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          required={item.tipo_criterio_vp ? true : false}
                          disabled={nombrePrivado()}
                          name="descripcion_criterio"
                          variant="outlined"
                          size="small"
                          multiline
                          value={item.descripcion_criterio || ''}
                          rows={4}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {t('certificateBadgeTemplate:badges-certificate-form.criterion-url')}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          disabled={nombrePrivado()}
                          type="url"
                          name="link"
                          variant="outlined"
                          size="small"
                          placeholder="http://"
                          value={item.link || ''}
                          onChange={(e) => handleInputCriteria(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      {form.activities?.length > 0 && (
                        <Button disabled={nombrePrivado()} size="small" onClick={(e) => deleteActivity(e, item.index)}>
                          <Typography color="textSecondary" variant="body2">
                            <CloseOutlined />
                            {t('buttons:buttons.delete')}
                          </Typography>
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <Button variant="outlined" color="primary" onClick={addActivity} disabled={nombrePrivado()}>
                  {t('buttons:buttons.add-criterion')}
                </Button>
              </Grid>
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.additional-fields')}
            </Typography>
            {form.fieldsAdditionals.map((item, index) => (
              <Grid container key={`grid-activity-${index}`} spacing={2} className={classes.root}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1" className={classes.text} align="right">
                    {`${t('certificateBadgeTemplate:badges-certificate-form.field')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.field-title')}${
                          item.descripcion_campo ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl required variant="outlined" className="w-100" size="small">
                        <TextField
                          required={item.descripcion_campo ? true : false}
                          disabled={nombrePrivado()}
                          name="titulo_campo"
                          variant="outlined"
                          size="small"
                          value={item.titulo_campo || ''}
                          rows={4}
                          onChange={(e) => handleInputFieldAdditional(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {`${t('certificateBadgeTemplate:badges-certificate-form.field-description')}${
                          item.titulo_campo ? '*' : ''
                        }`}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          multiline
                          required={item.titulo_campo ? true : false}
                          disabled={nombrePrivado()}
                          name="descripcion_campo"
                          variant="outlined"
                          size="small"
                          value={item.descripcion_campo || ''}
                          rows={4}
                          onChange={(e) => handleInputFieldAdditional(e, item.index)}
                          className={classes.fieldCard}
                        />
                      </FormControl>
                      <Typography variant="body1" align="left" className={classes.root}>
                        {t('certificateBadgeTemplate:badges-certificate-form.field-url')}
                      </Typography>
                      <FormControl className="w-100">
                        <TextField
                          disabled={nombrePrivado()}
                          label=""
                          name="link"
                          variant="outlined"
                          size="small"
                          placeholder="http://"
                          onChange={(e) => handleInputFieldAdditional(e, item.index)}
                          value={item.link || ''}
                          className={classes.fieldCard}
                          type="url"
                        />
                      </FormControl>
                    </CardContent>

                    <CardActions>
                      {form.fieldsAdditionals?.length > 0 && (
                        <Button
                          disabled={nombrePrivado()}
                          size="small"
                          onClick={(e) => deleteFieldAdditional(e, item.index)}
                        >
                          <Typography color="textSecondary" variant="body2">
                            <CloseOutlined />
                            {t('buttons:buttons.delete')}
                          </Typography>
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ))}

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <Button variant="outlined" color="primary" onClick={addFieldAdditional} disabled={nombrePrivado()}>
                  {t('buttons:buttons.add-field')}
                </Button>
              </Grid>
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.attributes')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.type')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="tipo"
                    name="tipo_vp"
                    defaultValue=""
                    value={form.tipo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTypes()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.level')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="nivel"
                    name="nivel_vp"
                    value={form.nivel_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataLevels()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="tiempo"
                    name="tiempo_vp"
                    value={form?.tiempo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value={''}>
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataTime()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.amount-time')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <TextField
                    disabled={requiredCantidadTiempo(form.tiempo_vp) || nombrePrivado()}
                    required={!requiredCantidadTiempo(form.tiempo_vp)}
                    type="number"
                    name="cantidad_tiempo"
                    value={cantidadTiempo(form.cantidad_tiempo)}
                    variant="outlined"
                    size="small"
                    onKeyUp={onlyNumber}
                    onChange={handleInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" align="right" className={classes.text}>
                  {t('certificateBadgeTemplate:badges-certificate-form.cost')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Select
                    id="costo"
                    name="costo_vp"
                    value={form?.costo_vp || ''}
                    disabled={nombrePrivado()}
                    onChange={handleInput}
                  >
                    <MenuItem value="">
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataCosts()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider />
            <Typography component="h6" variant="h6" className={classes.root}>
              {t('certificateBadgeTemplate:badges-certificate-form.recommendation-title')}
            </Typography>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={8}>
                <FormControl variant="outlined" className="w-100" size="small">
                  <Autocomplete
                    disabled={nombrePrivado()}
                    multiple
                    filterSelectedOptions={true}
                    className={classes.autocomplete}
                    value={form.id_plantillas_recomendadas || []}
                    onChange={handleRecommendation}
                    options={
                      certificateTemplates.map((element) => ({
                        linkphoto: element.linkPhoto,
                        id_plantillas_certificados_recomendadas: element.id,
                        nombre: element.nombre,
                        nombre_privado: element.nombre_privado,
                      })) || []
                    }
                    getOptionLabel={(option) => privateName(option)}
                    getOptionSelected={(option, value) =>
                      option.id_plantillas_certificados_recomendadas === value.id_plantillas_certificados_recomendadas
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((recommendation, index) => (
                        <Chip
                          key={`chip-${index}`}
                          variant="outlined"
                          label={privateName(recommendation)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('selects:selects-autocompletes.recommendation-select')}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} sm="auto">
                <Button disableElevation type="submit" color="primary" variant="contained" fullWidth>
                  {t('buttons:buttons.save')}
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button disableElevation color="primary" variant="contained" onClick={handleCancel} fullWidth>
                  {t('buttons:buttons.cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogActions>
          <Button variant="contained" className={`my-2 ${classes.buttonForm}`} onClick={handleClose}>
            <CloseOutlined />
          </Button>
        </DialogActions>
        <DialogContent align="center">
          <img
            alt="img-prev"
            src={
              form?.img_template
                ? form?.img_template[form?.page - 1]
                : form?.img_prev
                ? form?.img_prev[form?.page - 1]
                : null
            }
            className={classes.img}
          />
        </DialogContent>
      </Dialog>
      <Backdrop loading={loading} />
      <Dialog open={openDesignCertificate} fullScreen TransitionComponent={Transition}>
        <CertificateDesing setOpenDesignCertificate={setOpenDesignCertificate} />
      </Dialog>
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth={true} maxWidth={'sm'}>
        <DialogContent dividers>
          <FormControl className="w-100">
            <TextField
              autoFocus
              required
              multiline
              id="nombre"
              label={t('dialogs:dialog.skill')}
              variant="outlined"
              onChange={(e) => setSkillValue({ ...skillValue, value: e.target.value })}
              defaultValue={skillValue.value}
            />
          </FormControl>
        </DialogContent>
        <DialogActions center>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonDialog}`}
            onClick={editSkill}
          >
            {t('buttons:buttons.save')}
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            className={`my-2 ${classes.buttonDialog}`}
            onClick={handleCloseEdit}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 'auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    margin: 20,
    fontSize: '4em',
  },
  card: {
    backgroundColor: colors.background,
  },
  fieldCard: {
    backgroundColor: '#ffffff',
  },
  contentInput: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      alignItems: 'left',
    },
  },
  text: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  autocomplete: {
    width: 590,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    marginBottom: 10,
  },
  skills: {
    whiteSpace: 'normal',
  },
  chip: {
    height: '100%',
    marginBottom: '0.5em',
  },
  img: (props) => ({
    maxWidth: '70vw',
    height:
      props.form?.img_prev && props.form?.img_prev[0]?.split(':', 1)[0] === 'blob'
        ? props.imageRef?.current?.naturalHeight
        : props.form?.height,
  }),
  buttonForm: {
    position: 'absolute',
    top: '.5em',
    margin: '1em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    padding: '9px 20px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  buttonDialog: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: '50px',
    padding: '9px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    form: state.form
      ? {
          ...state.form,
          activities: state.form.activities?.length > 0 ? state.form.activities : [{ index: 1 }],
          fieldsAdditionals: state.form?.fieldsAdditionals?.length > 0 ? state.form?.fieldsAdditionals : [{ index: 1 }],
          modules: state.form?.modules?.length > 0 ? state.form?.modules : [{ index: 1, sessions: [] }],
        }
      : {
          activities: [{ index: 1 }],
          fieldsAdditionals: [{ index: 1 }],
          modules: [{ index: 1, sessions: [] }],
          skills: [],
        },
    certificateFields: state.certificateFields,
    returnRoute: state.returnRoute,
    saveDesign: state.saveDesign,
  };
};

const mapDispatchToProps = {
  setCertificateFields,
  changeBreadcrump,
  setForm,
  setSaveDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
